import { RouteObject } from 'react-router-dom'

import { eventsRoutes } from '../events/routes'
import { leaguesRoutes } from '../leagues/routes'
import { organizationsRoutes } from '../organizations/routes'
import { HomeView } from './HomeView'
import { HomePage } from './pages/HomePage'

export const routesHome: RouteObject = {
  path: '',
  element: <HomeView />,
  children: [
    {
      path: '',
      element: <HomePage />
    },
    eventsRoutes,
    leaguesRoutes,
    organizationsRoutes
  ]
}
