import './index.css'
import 'react-toastify/dist/ReactToastify.css'

import ReactDOM from 'react-dom/client'

import { AppRouter } from './app/AppRouter'
import { addPolyfills } from './shared/polyfills'

addPolyfills()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppRouter />)
