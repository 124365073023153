import { RouteObject } from 'react-router-dom'

import { AuthView } from './AuthView'
import { LoginPage } from './pages/LoginPage'
import { RequestAccessPage } from './pages/RequestAccessPage'

export const routesAuth: RouteObject = {
  path: 'auth',
  element: <AuthView />,
  children: [
    {
      path: 'login',
      element: <LoginPage />
    },
    {
      path: 'request-access',
      element: <RequestAccessPage />
    }
  ]
}
