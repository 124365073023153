import { Outlet, RouteObject } from 'react-router-dom'

import { OrganizationCreateUpdatePage } from './pages/OrganizationCreateUpdatePage'
import { OrganizationsPage } from './pages/OrganizationsPage'

export const organizationsRoutes: RouteObject = {
  path: 'organizations',
  element: <Outlet />,
  children: [
    { path: '', element: <OrganizationsPage /> },
    { path: 'create', element: <OrganizationCreateUpdatePage /> },
    { path: 'update/:id', element: <OrganizationCreateUpdatePage /> }
  ]
}
