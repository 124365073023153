import { apiBusinessClient, useApi } from '@petr-jilek/letssport-common'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

export function EventDetailPage() {
  const { nameUrl } = useParams()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading: eventDetailLoading, response: eventDetailResponse } = useApi({
    method: useCallback(() => apiBusinessClient.api.eventsGetEventDetailDetail(nameUrl ?? ''), [nameUrl]),
    callOnMount: true
  })

  if (eventDetailLoading) return <p>Loading...</p>

  if (!eventDetailResponse?.data?.baseData) return <p>Event not found</p>

  const eventDetail = eventDetailResponse?.data
  const event = eventDetail.baseData!
  const imageFile = eventDetail.imageFile
  const files = eventDetail.files

  return (
    <div>
      <h1>{event.name}</h1>
      {imageFile && (
        <img
          src={`data:image/png;base64, ${imageFile.fileData}`}
          alt={event.name ?? 'img'}
          style={{ width: '200px' }}
        />
      )}
      <p>{event.description}</p>
      <p>{event.date}</p>
      <p>{event.location}</p>

      {files && (
        <div>
          {files.map((file) => (
            <div key={file.fileName}>
              <a
                href={`data:application/pdf;base64, ${file.fileData}`}
                download={file.fileName}
                target="_blank"
                rel="noreferrer"
              >
                {file.fileName}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
