import { Link } from 'react-router-dom'

export function OrganizationsPage() {
  return (
    <div>
      <h1>Organization page</h1>

      <Link to="/organizations/add">Add league</Link>
    </div>
  )
}
