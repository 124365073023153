import { Outlet, RouteObject } from 'react-router-dom'

import { LeaguesCreateUpdatePage } from './pages/LeaguesCreateUpdatePage'
import { LeaguesPage } from './pages/LeaguesPage'

export const leaguesRoutes: RouteObject = {
  path: 'leagues',
  element: <Outlet />,
  children: [
    { path: '', element: <LeaguesPage /> },
    { path: 'create', element: <LeaguesCreateUpdatePage /> },
    { path: 'update/:id', element: <LeaguesCreateUpdatePage /> }
  ]
}
