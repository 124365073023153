import { Outlet, RouteObject } from 'react-router-dom'

import { EventDetailPage } from './pages/EventDetailPage'
import { EventsCreateUpdatePage } from './pages/EventsCreateUpdatePage'
import { EventsPage } from './pages/EventsPage'

export const eventsRoutes: RouteObject = {
  path: 'events',
  element: <Outlet />,
  children: [
    { path: '', element: <EventsPage /> },
    { path: 'detail/:nameUrl', element: <EventDetailPage /> },
    { path: 'create', element: <EventsCreateUpdatePage /> },
    { path: 'update/:nameUrl', element: <EventsCreateUpdatePage /> }
  ]
}
