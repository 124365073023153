import { apiBusinessClient, useApi } from '@petr-jilek/letssport-common'
import { Link } from 'react-router-dom'

export function EventsPage() {
  const {
    loading: eventsLoading,
    response: eventsResponse,
    apiCall: eventsApiCall
  } = useApi({
    method: apiBusinessClient.api.eventsGetEventsList,
    callOnMount: true
  })

  const { loading: deleteEventLoading, apiCall: deleteEventApiCall } = useApi({
    method: apiBusinessClient.api.eventsDeleteEventDelete,
    callbackAfterStopLoading: eventsApiCall
  })

  return (
    <div>
      <h1>Events page</h1>

      <Link to="/events/create">Add event</Link>

      {eventsLoading && <p>Loading...</p>}

      {!eventsLoading && eventsResponse && (
        <div>
          {eventsResponse.data?.events?.map((event) => (
            <div
              key={event.id}
              style={{
                padding: '10px',
                border: '1px solid black'
              }}
            >
              <h2>{event.name}</h2>
              <p>{event.description}</p>
              <p>{event.date}</p>
              <p>{event.location}</p>

              <Link to={`/events/detail/${event.nameUrl}`}>Detail</Link>
              <br />

              <Link to={`/events/update/${event.nameUrl}`}>Edit</Link>
              <br />

              <button onClick={() => deleteEventApiCall(event.id ?? '')} disabled={deleteEventLoading}>
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
