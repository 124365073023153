import { Link } from 'react-router-dom'

export function LeaguesPage() {
  return (
    <div>
      <h1>Leagues page</h1>

      <Link to="/leagues/add">Add league</Link>
    </div>
  )
}
